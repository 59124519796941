@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    input {
        @apply border border-black p-1 col-span-3
    }


    .smNav{
        @apply h-[8svh] w-full
    }

    /* .hdr{
        @apply uppercase font-bold text-left 
    } */
    .hdr{
        @apply sticky top-[8svh] px-10 py-2 z-40 bg-inherit w-full uppercase font-extrabold text-left text-[20px] 
    }

    /* ------------------------------ */

    .xs {
        @apply leading-tight top-[75%] w-full  p-4
    }

    .xs > h3 {
        @apply text-[21px] font-semibold leading-tight text-left
    }    
    
    .xs > p{
        @apply text-[17px] font-extralight pl-1 pt-1
    }

    /* ------------------------------- */

    .sm {
        @apply leading-tight
    }

    .sm > h3 {
        @apply text-[18px] font-semibold leading-tight text-left
    }    
    
    .sm > p{
        @apply text-[14px] font-light pl-1 pt-1
    }

    /* ------------------------------- */


    .md {
        @apply leading-tight top-[75%] w-full  p-2
    }

    .md > h3 {
        @apply text-[18px] font-bold tracking-tight text-left
    }    
    
    .md > p{
        @apply text-[14px] font-extralight pl-1 pt-1
    }

    /* ---------------------------------- */

    .lg {
        @apply leading-tight top-[70%] w-full  p-2
    }

    .lg > h3 {
        @apply text-[16px] font-bold tracking-tight text-left
    }    
    
    .lg > p{
        @apply text-[12px] font-extralight pl-1 pt-1
    }

    /* ------------------------------------- */

    .blurbsContainer{
        @apply grid gap-10 py-[5svh] lg:grid-cols-3 text-black
    }
    .blurb {
        @apply w-full grid gap-4 md:w-full sm:mx-auto border rounded-2xl p-8 bg-[#f3f2f0] shadow-xl sm:hover:animate-elevate
    }

    .blurb > img {
        @apply h-[50px] 
    }

    .blurb > h3 {
        @apply text-[21px] font-semibold
    }

    .blurb > p {
        @apply text-[17px] 
    }

    .money  {
        @apply text-[17px] text-[#c85004] font-bold text-right mr-auto rounded-xl
    }

}